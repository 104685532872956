import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import TopSection from '../components/sections/TopSection';
import OverviewSection from '../components/sections/OverviewSection';
import MercCustSection from '../components/sections/MercCustSection';
// import DirectLoanSection from '../components/sections/DirectLoanSection';
// import CreditScoreSection from '../components/sections/CreditScoreSection';
const HomePage = () => {
    return (
    <div>
        <TopBar />
        <main className="pt-1">
            <TopSection />
            <OverviewSection />
            <MercCustSection />
            <Footer /> 
            {/* <FeatureSection /> */}
            {/* <OverviewSection />
            <DirectLoanSection />
            <CreditScoreSection />
            <Footer /> */}
        </main>
    </div>
    )
}

export default HomePage;