
import React, { useState } from 'react';
import section1Gif from '../../assets/images/wallet.gif';
import section1Img from '../../assets/images/2ppl.png';
import storeIcons from '../../assets/images/store_icons.png';
import axios from 'axios';

const TopSection = () => {

    return (
        <section className="bg-gradient-to-r from-green-400 to-green-200 pt-40 mx-auto px-6 flex flex-wrap md:flex-nowrap justify-center items-center">
            <div className="text-center w-full mb-8 md:mb-0 md:w-1/2 ">
                <h1 className="text-6xl font-bold mb-4 pt-4 text-right text-green-900">
                    Make <span className='text-black'>payments simple </span><br></br>
                    for <span className='text-black'>yourself</span> and your <span className='text-black'>customers</span><br></br>
                </h1>
                <div className="flex justify-end">
                    <img src={storeIcons} alt="Store Icons" className="max-w-xs md:max-w-lg" style={{ transform: 'scale(0.6)' }} />
                </div>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
                <img src={section1Img} alt="Descriptive Alt Text" className="h-auto  p-4" />
            </div>
        </section>


    );
};

export default TopSection;