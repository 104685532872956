import { MoneyHand } from '@styled-icons/fluentui-system-filled/MoneyHand'
import { Lock, Analytics } from '@styled-icons/material-sharp'
import happyGirl from '../../assets/images/happy_girl.png';
import happyGuy from '../../assets/images/happy_guy.png';
import { Key } from '@styled-icons/remix-line'

const OverviewItem = ({ title, description, icon, color }) => (
    <div className="text-center p-4">
        <div className="mb-4 inline-block p-4 rounded-full bg-[#000000]">{icon}</div>
        <h4 className="text-xl font-semibold mb-2">{title}</h4>
        <p className='font-medium text-gray-500'>{description}</p>
    </div>
);

const OverviewSection = () => (
    <section className="container mx-auto px-6 py-12 flex flex-wrap md:flex-nowrap">
        <div className="text-center w-full mb-8 md:mb-0 md:w-1/2 rounded-lg shadow-md">
            <h2 className="text-4xl font-bold mb-4 pt-4">What are you waiting for?</h2>
            <p className="mb-12">You are a few steps away from being a part of Clude</p>

            {/* Grid for Overview Items */}
            <div className="grid grid-cols-2 gap-8">
                <OverviewItem
                    icon={<MoneyHand size={44} color='#fff' />}
                    title="Collect money with ease"
                    description="No stress, no hassle."
                />
                <OverviewItem
                    icon={<Key size={44} color='#fff' />}
                    title="End-to-end encryption"
                    description="Every transaction is encrypted."
                />
                <OverviewItem
                    icon={<Lock size={44} color='#fff' />}
                    title="Fast transfers"
                    description="Blazing fast and secure transfers."
                />
                <OverviewItem
                    icon={<Analytics size={44} color='#fff' />}
                    title="Dashboard analytics"
                    description="Analytics to help you stay on top."
                />
            </div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center">
            <img src={happyGuy} alt="Descriptive Alt Text" className="h-auto p-4" />
        </div>
    </section>

);

export default OverviewSection;