import merchant from '../../assets/images/merchant2.png';
import merchant3 from '../../assets/images/merchant3.png';
import customer from '../../assets/images/customer.png';
import pos from '../../assets/images/pos.png';
import lock from '../../assets/images/lock.png';
import coupon from '../../assets/images/coupon.png';
import group from '../../assets/images/group.png';
const MercCustSection = () => (
    <div>
        <section className="container mx-auto my-12 p-4 grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="bg-green-100 rounded-lg overflow-hidden">
                <div className="flex justify-center items-center">
                    <img src={merchant} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                </div>
                <div className="flex flex-col justify-center p-4">
                    <h2 className="text-3xl font-bold mb-2 text-center">Merchant and Customer</h2>
                    <p className="text-gray-600 font-medium text-center">
                        For online merchants and their customers, we can hold on to payments until customer
                        receives product/service and confirms satisfaction
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-center bg-blue-100 rounded-lg">
                <div className="flex justify-center items-center">
                    <img src={customer} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                </div>
                <div className="flex flex-col justify-center p-4">
                    <h2 className="text-3xl font-bold mb-2 text-center">Merchant and Customer</h2>
                    <p className="text-gray-600 font-medium text-center">
                        Payment and notifications are in the hands of the merchant. Verification is in the hands of the customer
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-center bg-purple-100  rounded-lg">
                <div className="flex justify-center items-center">
                    <img src={merchant3} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                </div>
                <div className="flex flex-col justify-center p-4">
                    <h2 className="text-3xl font-bold mb-2 text-center">Merchant and Customer</h2>
                    <p className="text-gray-600 font-medium text-center">
                        Payment and notifications are in the hands of the merchant. Verification is in the hands of the customer
                    </p>
                </div>
            </div>
        </section>

        <div className='text-center'>
            <h2 className="text-4xl font-bold mb-4 pt-4">Clude has you covered </h2>
            <p className="mb-12">Get Clude, get included (in the digital economy)</p>
        </div>

        <section className="container mx-auto my-12 p-4 grid grid-cols-1 lg:grid-cols-4 gap-8">
            <div>
                <div className="bg-gray-100 rounded-lg overflow-hidden">
                    <div className="flex justify-center items-center">
                        <img src={pos} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <h2 className="text-3xl font-bold mb-2 mt-4">Join Easy</h2>
                    <p className="text-gray-600 font-medium">
                    No need for an expensive POS to participate in the digital economy
                    </p>
                </div>
            </div>
            <div>
                <div className="bg-gray-100 rounded-lg overflow-hidden">
                    <div className="flex justify-center items-center">
                        <img src={group} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <h2 className="text-3xl font-bold mb-2 mt-4">Multi-capable</h2>
                    <p className="text-gray-600 font-medium">
                    Merchants are able to manage multiple branches and/or salespeople
                    </p>
                </div>
            </div>
            <div>
                <div className="bg-gray-100 rounded-lg overflow-hidden">
                    <div className="flex justify-center items-center">
                        <img src={coupon} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <h2 className="text-3xl font-bold mb-2 mt-4">Low Fees</h2>
                    <p className="text-gray-600 font-medium">
                    Flexibility with minimum setup fees. No hidden charges
                    </p>
                </div>
            </div>
            <div>
                <div className="bg-gray-100 rounded-lg overflow-hidden">
                    <div className="flex justify-center items-center">
                        <img src={lock} alt="Transactions" className="h-auto" style={{ transform: 'scale(0.6)' }} />
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <h2 className="text-3xl font-bold mb-2 mt-4">Safe and Secure</h2>
                    <p className="text-gray-600 font-medium">
                    Inbuilt security features protects the merchant and customer
                    </p>
                </div>
            </div>
        </section>
    </div>

);

export default MercCustSection;